<template>
  <div class="container">
    <div class="container__control">
      <ClosePopUpButton
        class="black"
      />
    </div>

    <div class="container__content">
      <h3>
        {{ $t('components.ui.pages.pop-up.sign-out.sign-out') }}
      </h3>

      <BlueButton
        :text="$t('components.ui.pages.pop-up.sign-out.logout')"
        @click="$auth.removeUser({})"
      />

      <div class="container__content__mb">
        <a @click="$pop.down()">
          {{ $t('components.ui.pages.pop-up.sign-out.cancel') }}
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import ClosePopUpButton from '@/ui/buttons/ClosePopUpButton.vue'
import BlueButton from '@/ui/buttons/BlueButton.vue'

export default {
  components: {
    ClosePopUpButton,
    BlueButton
  }
}
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  max-width: 430px;
  padding: 10px;
  background: white;
  border-radius: 15px;
  overflow: hidden;

  .container__control {
    display: flex;
    justify-content: flex-end;
  }

  .container__content {
    padding: 0 10px 20px 10px;

    h3 {
      margin-bottom: 30px;
      line-height: 100%;
      color: rgb(var(--color-dark));
      font-family: SemiBold;
      text-align: center;
    }

    .container__content__mb {
      margin: 20px 0 0;
      text-align: center;

      a {
        font-size: 14px;
        line-height: 100%;
        color: rgb(var(--primary-color));
        font-family: SemiBold;
        text-transform: uppercase;
        text-decoration: none;
        cursor: pointer;
      }
    }
  }
}
</style>
